import * as React from 'react';
import { Box } from '@mui/material';

import SingleGift from './SingleGift';


export default function GiftsList({ gifts }) {

    return (
        <Box sx={{ display: "flex", justifyContent: { md: "space-evenly", xs: 'center' }, flexWrap: "wrap",  }}>
            {gifts.map(gift => <SingleGift product={gift} key={gift.title} />)}
        </Box>
    )
}