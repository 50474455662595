import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { generalTheme } from '../../style/generalTheme';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function SingleGift({product}) {

    const { t } = useTranslation();

    const isAffiliateLink = product.affiliateNetwork === 'none' ? false : true;
    
    return (
        <>
            <Card sx={{ width: '250px', margin: '8px', display: {xs: 'none', sm: 'flex'}, flexDirection: "column", justifyContent: 'center', alignItems: 'center', marginBottom: 5 }} >
                <a href={product.affiliateLinkWeb} target="_blank" rel={isAffiliateLink ? "noopener noreferrer sponsored" : 'noopener noreferrer'}>
                    <picture>
                        <source srcSet={product.images.image300 + ', ' + product.images.image450 + ' 1.5x , ' + product.images.image600 + ' 2x'} />
                        <img
                            src={product.images.image300}
                            alt={product.title}
                            style={{ height: '250px', objectFit: "contain", marginLeft: 'auto', marginRight: 'auto' }}
                        />
                    </picture>
                </a>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 'auto', textAlign: "center", lineHeight: "1.2" }}>
                        {product.title}
                    </Typography>
                </CardContent>
                <CardActions style={{ display: "flex", justifyContent: "center", marginBottom: "8px", marginTop: "auto", }}>
                    <Button
                        size="medium"
                        variant='contained'
                        href={product.affiliateLinkWeb}
                        target='_blank'
                        rel={isAffiliateLink ? "noopener noreferrer sponsored" : 'noopener noreferrer'}
                        sx={{
                            backgroundColor: generalTheme.palette.primary.main,
                            ":hover": {
                                bgcolor: generalTheme.palette.secondary.main,
                                color: "white"
                            }
                        }}

                    >{t('toGift')}{isAffiliateLink ? '*' : ''}</Button>
                </CardActions>
            </Card>

            <Card sx={{ display: {xs: 'flex', sm: 'none'}, width: '100%', marginBottom: 5 }} >
                {/* <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={product.images.image300}
                    alt={product.title}
                /> */}
                <Box sx={{display: 'flex', height:'100%', flexWrap: 'wrap', flex: 0.35,  alignContent:'center'}}>
                    <a href={product.affiliateLinkWeb} target="_blank" rel={isAffiliateLink ? "noopener noreferrer sponsored" : 'noopener noreferrer'}>
                        <picture>
                            <source srcSet={product.images.image150 + ', ' + product.images.image300 + ' 1.5x , ' + product.images.image450 + ' 2x'} />
                            <img
                                src={product.images.image150}
                                alt={product.title}
                                style={{ height: '150px', width: '150px', objectFit: "contain", marginBottom:'auto', marginTop:'auto', }}
                            />
                        </picture>
                    </a>
                </Box>
                <Box sx={{flex: 0.65 }}>
                    <CardContent sx={{}}>
                        <Typography component="div" variant="h6" sx={{textAlign: 'left'}}>
                            {product.title}
                        </Typography>
                    </CardContent>
                    <CardActions style={{ display: "flex", justifyContent: "left", marginBottom: "4px", marginTop: "auto", }}>
                        <Button
                            size="medium"
                            variant='contained'
                            href={product.affiliateLinkWeb}
                            target='_blank'
                            rel={isAffiliateLink ? "noopener noreferrer sponsored" : 'noopener noreferrer'}
                            sx={{
                                backgroundColor: generalTheme.palette.primary.main,
                                ":hover": {
                                    bgcolor: generalTheme.palette.secondary.main,
                                    color: "white"
                                }
                            }}

                        >{t('toGift')}{isAffiliateLink ? '*' : ''}</Button>
                    </CardActions>
                </Box>
            </Card>
        </>
    )
}