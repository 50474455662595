import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { I18nextProvider, useTranslation } from "react-i18next";
import SEO from "../components/seo";
import GiftsList from "../components/giftList/GiftsList";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, List, ListItem, ListItemButton, ListItemText, Typography, Menu, MenuItem, Button, Divider } from "@mui/material";
import { generalTheme } from "../style/generalTheme";
import { Helmet } from "react-helmet";

export default function Template({ data, pageContext: { gifts, navId, navText } }) {
  // console.log({data})
  const { markdownRemark, locales } = data
  const { frontmatter, html, } = markdownRemark

  const LANGUAGE = frontmatter.lang;
  const translationLink = frontmatter.translationLink;
  const path = frontmatter.path;

  const { t, i18n } = useTranslation();

  // React.useEffect(() => {
  //   i18n.changeLanguage(frontmatter.lang);
  // }, [frontmatter.lang, i18n]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <I18nextProvider i18n={i18n} i18nOptions={{ resources: locales }}>
      <SEO title={frontmatter.metaTitle} description={frontmatter.metaDescription} />
      {
        LANGUAGE == "de" || LANGUAGE == "DE" ? 
        <Helmet>
          <link data-custom-config="hreflanglink" rel="alternate" hreflang="en" href={`https://present-helper.com${translationLink}`} />
          <link data-custom-config="hreflanglink" rel="alternate" hreflang="de" href={`https://present-helper.com/de${path}`} />
          <link data-custom-config="hreflanglink" rel="alternate" hreflang="x-default" href={`https://present-helper.com${translationLink}`} />
        </Helmet>
        : 
        <Helmet>
          <link data-custom-config="hreflanglink" rel="alternate" hreflang="en" href={`https://present-helper.com${path}`} />
          <link data-custom-config="hreflanglink" rel="alternate" hreflang="de" href={`https://present-helper.com/de${translationLink}`} />
          <link data-custom-config="hreflanglink" rel="alternate" hreflang="x-default" href={`https://present-helper.com${path}`} />
        </Helmet>
      }
      <Helmet>

      </Helmet>
      <Layout>
        <Box sx={{
          display: 'none',
          position: "fixed",
          right: 0,
          top: 120,
          '@media (min-width:1350px)': {
            display: 'block',
          },
        }}>
          <Typography sx={{ fontWeight: 'bold', textAlign: 'center', textDecoration: "underlined" }}>
            {t('inhalt')}
          </Typography>
          <List>
            {navId.map((item, i) => {
              return (
                <ListItem disablePadding key={item}>
                  <ListItemButton component={Link} to={`#${item}`}>
                    <ListItemText primary={navText[i]} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Box>

        <Typography component="h1" sx={{
          fontWeight: 'bold',
          textAlign: 'left',
          fontSize: { xs: "1.6em", sm: "2em" }

        }}>
          {frontmatter.title}
        </Typography>
        {/* <h1 style={{ 
          textAlign: "left",  
          '@media (maxWidth:600px)': {
            fontSize: '16px',
          },
          }}>{frontmatter.title}</h1> */}

        <Box sx={{
          display: 'none',
          top: 24,
          right: 0,
          position:'sticky',
          justifyContent: "flex-start",
          '@media (max-width:1349px)': {
            display: 'flex',
          },
          zIndex: 100,
          marginY: 2,
        }}>
          <Button
            id="content-button"
            aria-controls={open ? 'content-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
            sx={{
              position: 'sticky',
              backgroundColor: generalTheme.palette.primary.main,
              ":hover": {
                bgcolor: generalTheme.palette.secondary.main,
                color: "white"
              }
            }}
          >
            {t('inhalt')}<ArrowDropDownIcon />
          </Button>
          <Menu
            id="content-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'content-button',
            }}
          >
            {navId.map((item, i) => {

              return <MenuItem
                component={Link}
                to={`#${item}`}
                onClick={handleClose}
                key={item}
              >{navText[i]}</MenuItem>

            })}
          </Menu>
        </Box>
        {frontmatter.introduction === '' ? null : <p>{frontmatter.introduction}</p>}
        {
          gifts.length === 0 ? null : <>
            <h2 id={t('giftIdeasAnker')}>{t('giftIdeas')}</h2>
            <GiftsList gifts={gifts} />
          </>
        }
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <Divider sx={{marginBottom: 3}} />
        <p>
          *{t('affiliateLinks')}
        </p>
      </Layout>
    </I18nextProvider>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        path
        translationLink
        lang
        title
        metaTitle
        metaDescription
        introduction
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
  }
`
